import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Thank you | Service Center</title>
        <meta
          name="description"
          content="We service & repair all types of small petrol engines: lawnmowers, chainsaws, water blasters, compressors, leaf blowers, generators, and trimmers"
        />
        <meta
          property="og:image"
          content="https://servicecentre.co.nz/app/uploads/service-centre-building.jpg"
        />
        <meta
          property="og:image:alt"
          content="Mitre 10 Mega Masterton Service Centre"
        />
      </Helmet>

      <Layout title="Thank you">
        <section className="container">
          <div class="alert alert-success">
            Thank you for your message. We will be in touch soon.
          </div>
        </section>
      </Layout>
    </>
  )
}
